/* MenuBar 
================================================== */

.tl-menubar, .tl-menubar-toggle {
	transition: opacity 144ms ease-in-out;
	position:absolute;
	//width:100%;
	//height:	1px;
	z-index:5555;
	text-align:center;
	color:#333;
	//height:26px;
	//background-color:#FFF;
	//.box-shadow(0px -3px 6px rgba(0,0,0,.20));
	overflow:hidden;
	//margin-top:-25px;
	//border-top: 1px solid darken(@color-background,10);
	//border-bottom: 1px solid darken(@color-background,10);
	//border-right: 1px solid darken(@color-background,10);
	
	//.border-bottom-radius(7px);
	border-bottom-right-radius: 10px;
	border-top-right-radius: 10px;
	top:100%;
	left:50%;
	left:0;

	&-toggle {
		display: none;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: rgba(255, 255, 255, .7);

		span {
			display: block;
			width: 40%;
			margin-bottom: 3px;
			border: 1px solid black;
		}

	}
}
/* Skinny
================================================== */
.tl-skinny {
	.tl-menubar {
		&-toggle {
			display: flex;
		}
		.tl-menubar-button {
			opacity: 0;
		}
	}

	&.timenav-visible {
		.tl-menubar {
			&-button {
				opacity: 1;

			}

			&-toggle {
				opacity: 0;
				pointer-events: none;
			}
		}
	}
}



/* Mobile, iPhone
================================================== */
.tl-mobile {
	.tl-menubar {
		&-toggle {
			display: flex;
		}
		.tl-menubar-button {
			opacity: 0;
		}
	}

	&.timenav-visible {
		.tl-menubar {
			&-button {
				opacity: 1;

			}

			&-toggle {
				opacity: 0;
				pointer-events: none;
			}
		}
	}
}

/* Mobile, iPhone and skinny
================================================== */
.tl-mobile.tl-skinny {
	.tl-menubar {
		&-toggle {
			display: flex;
		}
		.tl-menubar-button {
			opacity: 0;
		}
	}

	&.timenav-visible {
		.tl-menubar {
			&-button {
				opacity: 1;

			}

			&-toggle {
				opacity: 0;
				pointer-events: none;
			}
		}
	}
}


/* Color
================================================== */

// Inverted
/*
.tl-sizebar.tl-sizebar-inverted {
	border-bottom: 1px solid #FFF;
	//background-color:#000;
	color:#a5a5a5;
	.tl-sizebar-button {
		border-left: 1px solid darken(@color-background, 70);
		//color:#a5a5a5;
	}
	.tl-sizebar-button:hover {
		//background:@color-theme;
		color:@color-background;
	}
}
.tl-sizebar.tl-sizebar-inverted:before {
	background-color:#000;
	//.gradient-vertical (rgba(0,0,0,0.25), rgba(0,0,0,1));
	//.translucent-background(rgb(0,0,0), .5);
	border-top: 2px solid #000;
	animation: invertToBlack 1s;
	-webkit-animation:invertToBlack 1s; 
}
*/

@keyframes invertToBlack {
	from {
		background-color:#FFF;
	}
	to {
		background-color:#000;
	}
}
@-webkit-keyframes invertToBlack {
	from {background:#FFF;}
	to {background:#000;}
}
@keyframes invertToWhite {
	from {background-color:#000;}
	to {background-color:#FFF;}
}
@-webkit-keyframes invertToWhite{
	from {background:#000;}
	to {background:#FFF;}
}


