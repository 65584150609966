/* SLIDE
================================================== */
.tl-slide {
	position:absolute;
	width:100%;
	height:100%;
	padding:0px;
	margin:0px;
	overflow-x:hidden;
	overflow-y:auto;

	.tl-slide-background {
		position:absolute;
		left:0;
		top:0;
		width:100%;
		height:100%;
		z-index:-1;
		overflow:hidden;
		display:none;
		.opacity(50);
		background: no-repeat center center;
		-webkit-background-size: cover;
		   -moz-background-size: cover;
		     -o-background-size: cover;
			    background-size: cover;
	}
	.tl-slide-scrollable-container {
		height:100%;
		z-index:1;
	}
	.tl-slide-content-container {
		position:relative;
		width:100%;
		height:100%;
		display: flex;
		z-index:3;
		.tl-slide-content {
			//width:100%;
			display: flex;
			padding-left:100px;
			padding-right:100px;
			position:relative;
			max-width:100%;
			user-select: text;
			.tl-media {

				&-content-container{
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					height: 100%;
				}
				position:relative;
				width:100%;
				min-width:50%;
				//height:100%;
				margin-top:auto;
				margin-bottom:auto;
				//margin-right:auto;
				img, embed, object, video, iframe {
					//width:100%;
				}
			}
			.tl-text {
				width:50%;
				max-width:50%;
				min-width:120px;
				//height:100%;
				//overflow-y:auto;
				padding: 0 20px 0 20px;
				display: flex;
				align-items: center;
				text-align: left;
				//float:left;
			}
		}
	}

	&.tl-slide-titleslide {
		.tl-slide-content-container {
			align-items: center;
		}
		b, strong {
			font-weight: 600;
			padding: 0 0.3rem;
			background-color: #FFF;
			cursor: pointer;
			//color: @color-dark;
		}
		
	}
}

/* Only Media (no text)
================================================== */
.tl-slide-media-only {
	.tl-slide-content-container {
		.tl-slide-content {
			//width:100%;
			text-align:center;
			.tl-media {
				//display:table-cell;
				//vertical-align:middle;
				text-align:center;
				position:relative;
				width:100%;
				min-width:50%;
				max-width:100%;
				//height:100%;
				float: none;
				margin-top:auto;
				margin-bottom:auto;
				//margin-right:auto;
				img, embed, object, video, iframe {
					//width:100%;
				}
			}
			.tl-text {
				width:				100%;
				max-width:			100%;
				display:			block;
				margin-left:		auto;
				margin-right:		auto;
				text-align: 		center;
				h2 {
					//margin-top: 	20px;
					//margin-bottom: 	20px;
				}
				//float:left;
			}
		}
	}
}

/* Only Text (no media)
================================================== */
.tl-slide-text-only {
	.tl-slide-content-container {
		.tl-slide-content {
			//width:100%;
			text-align:center;
			.tl-text {
				max-width:80%;
				width:80%;
				display:block;
				margin-left:auto;
				margin-right:auto;
				//float:left;
			}
		}
	}
}
/* Background
================================================== */




/* Full Image Background
================================================== */
.tl-slide.tl-full-image-background {
	background: no-repeat center center;
	-webkit-background-size: cover;
	   -moz-background-size: cover;
	     -o-background-size: cover;
		    background-size: cover;
	//filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='.myBackground.jpg', sizingMethod='scale');
	//-ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(src='myBackground.jpg', sizingMethod='scale')";
	background-position:center 25%;
	.tl-slide-content-container {
		//.translucent-background(#000, 0.50);
	}


}
/* Color Background
================================================== */
.tl-slide.tl-full-color-background {

}
/* Text Background
================================================== */
.tl-slide.tl-text-background {
	.tl-text {
		.tl-text-content-container {
			padding:20px;
			.background-color-opacity(0,0,0, 60);
			.border-radius(7px);
			h2 {
				margin-top:5px;
			}
		}
	}
}



/* Skinny
================================================== */
.tl-skinny {

	.tl-slide {
		display:block;
		padding-top:10px;
		.tl-slide-content-container {
			display:block;
			position:relative;
			height:auto;
			height:100%;
			//flex-direction:column-reverse;
		 /* Safari 7.0+ */
			.tl-slide-content {
				height: 100%;
    		display: -webkit-flex; /* Safari */
				display: flex;
				justify-content: space-between;
				align-items: center !important;
				flex-direction:column;
				-webkit-flex-direction:column; /* Safari */
				
				padding-left:50px;
				padding-right:50px;
				.tl-media {
					position:static;
					width:100%;
					height:auto;
					float: none;
					display:block;
					//margin-top:20px;
					padding-top:20px;
					//padding-bottom:20px;
					border-top: 1px solid @ui-background-color-darker;
				}
				.tl-text {
					display:block;
					height:auto;
					position:static;
					width:100%;
					max-width:100%;
					min-width:0;
					float:none;
					padding: 0;

					.tl-text-content-container {
						padding-left:10px;
						padding-right:10px;
						padding-bottom:10px;
					}
				}
			}
		}

		&.tl-slide.tl-full-color-background, &.tl-full-image-background {
			.tl-slide-content-container {
				.tl-slide-content {
					.tl-media {
						border-color: fadeout(@ui-background-color-darker,75);
					}
				}
			}
		}

		&.tl-slide-media-only {
			.tl-slide-content-container {
				.tl-slide-content {
					flex-direction:column;
					-webkit-flex-direction:column; /* Safari */
					.tl-media {
						border-top: none;
						padding-top:0px;
					}
				}
			}

		}
	}

	.tl-slide-titleslide .tl-slide-content {
		position: absolute;
		top: 50%;
		transform: translateY(-30%);
	}
}



/* Mobile, iPhone
================================================== */
.tl-mobile {
	.tl-storyslider {

	}
}

/* Mobile, iPhone and skinny
================================================== */
.tl-mobile.tl-skinny {
	.tl-slide {
		.tl-slide-content-container {
			.tl-slide-content {
				.tl-media {
					img, embed, object, video, iframe {
						max-height:175px;
					}
				}
			}
		}
	}
}
